<template>
  <b-container>
    <transition name="slide">
      <div class="row-content" v-if="isVisible">
        <b-row>
          <b-col class="mt-4">
            <div class="title-text">Ainda sobre sua bike...</div>
            <div class="title-ask">Sua bike é nova?</div>
            <div class="title-description">
              Consideramos sua bike nova, até 30 dias após a compra.
            </div>
            <div class="row-btn mt-3">
              <button :class="['btn', { selected: newBike === true }]" @click="newBike = true">
                Sim, é nova
              </button>
              <button :class="['btn', { selected: newBike === false }]" @click="newBike = false">
                Não, é usada
              </button>
            </div>
          </b-col>
        </b-row>

        <b-row>
          <b-col class="">
            <div class="title-ask">Sua bike possui nota fiscal?</div>

            <div class="row-btn mt-3">
              <button :class="['btn', { selected: haveNF === true }]" @click="haveNF = true">
                Sim, possuo nota fiscal
              </button>
              <button :class="['btn', { selected: haveNF === false }]" @click="haveNF = false">
                Não tenho nota fiscal
              </button>
            </div>
          </b-col>
        </b-row>
        <b-row>
          <b-col class="p-3">
            <div class="box-info">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" :class="{ 'info-icon': true, active: error }"
                fill="none" stroke="currentColor" stroke-width="2">
                <circle cx="12" cy="12" r="9"></circle>
                <line x1="12" y1="8" x2="12" y2="9"></line>
                <line x1="12" y1="11" x2="12" y2="17"></line>
              </svg>
              {{ message }}
            </div>
          </b-col>
        </b-row>
      </div>
    </transition>
    <button id="footerButton" class="footer-button-back" @click="previousStep">
      Voltar
    </button>
    <button id="footerButton" class="footer-button" @click="handleClick" :disabled="newBike === null ||
      newBike === undefined ||
      haveNF === null ||
      haveNF === undefined ||
      loading
      ">
      Avançar
    </button>
  </b-container>
</template>
<script>
import * as D from "@/duck";
export default {
  components: {},
  props: {
    clickHandler: {
      type: Function,
      required: true,
    },
    previousStep: {
      type: Function,
      required: true,
    },
    data: {
      type: Object,
      default: () => ({}),
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  data: function () {
    return {
      brands: D.constants.BRANDS,
      selected: true,
      newBike: null,
      haveNF: null,
      error: false,
      isVisible: false,
      message:
        " Não se preucupe, caso não possua a nota fiscal, a mesma não é obrigatória para contratação.",
      selectedButton: null,
      fields: {
        option: "",
      },
    };
  },
  methods: {
    selectOption(option) {
      this.selected = option;
      this.fields.option = option;
      this.emitBikeInfoData();
    },
    handleClick() {
      if (this.newBike !== null && this.haveNF !== null) {
        this.emitBikeInfoData();
        this.clickHandler();
      } else {
        this.error = true;
        this.message = "As informações acima são obrigatórias";
      }
    },
    selectButton(buttonId) {
      this.selectedButton = buttonId;
    },
    initializeFields() {
      if (this.data) {
        this.haveNF = this.data.haveNF;
        this.newBike = this.data.newBike;
      }
    },
    emitBikeInfoData() {
      this.$emit("bikeInfoData", {
        haveNF: this.haveNF,
        newBike: this.newBike,
      });
    },
  },
  mounted() {
    this.initializeFields();
    this.isVisible = true;
  },
};
</script>
<style scoped>
.col,
.col-2,
.col-6 {
  padding: 0 10px;
}

.continue {
  background-color: #5db247;
  border-radius: 25px;
  border: none;
  color: #ffffff;
  font-size: 16px;
  font-weight: 300;
  line-height: 24px;
  padding: 12px 30px;
  max-width: 120px;
  position: absolute;
  bottom: 1%;
  right: 2%;
}

.box-info {
  display: flex;
  align-items: center;
  background-color: #f3f3f3;
  /* Fundo cinza claro */
  border-radius: 4px;
  /* Borda arredondada */
  padding: 10px;
  /* Espaçamento interno */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  /* Sombra sutil */
  margin: 10px;
  /* Margem externa */
}

.slide-enter-active,
.slide-leave-active {
  transition: transform 0.5s ease;
}

.slide-enter,
.slide-leave-to

/* Início e fim da animação */
  {
  transform: translateX(-100%);
}

.box-info p {
  margin: 0;
  /* Remove margem padrão do parágrafo */
  color: #333;
  /* Cor do texto */
  font-size: 14px;
  /* Tamanho do texto */
  line-height: 1.4;
  /* Altura da linha */
}

.title-text {
  font-size: 23px;
  line-height: 24px;
  font-weight: bold;
  color: #5db247;
}

.row-content {
  margin-top: 20px;
}

.box-info {
  padding: 4px;
  font-size: 12px;
  background-color: #e9e9e9;
  border-radius: 10px;
  color: #78849e;
}

.info-icon {
  color: #919ab0;
  width: 40px;
  height: 40px;
  margin-right: 10px;
}

.info-icon.active {
  width: 24px;
  height: 24px;
  margin-right: 10px;
  color: red;
}

.title-ask {
  font-size: 16px;
  margin-top: 50px;
  line-height: 24px;
  color: #5db247;
}

.row-btn {
  display: flex;
  align-items: center;
  margin-right: 25px;
}

.title-link {
  font-size: 16px;
  line-height: 24px;
  font-weight: bold;
  color: #5db247;
  text-decoration: underline;
}

.title-description {
  font-size: 16px;
  line-height: 24px;
  color: #919ab0;
}

.footer-button {
  position: fixed;
  bottom: 0;
  right: 0;
  width: 70%;
  height: 60px;
  background-color: #4fa935;
  color: white;
  font-size: 16px;
  border: none;
  cursor: pointer;
  z-index: 9999;
}

.footer-button:disabled {
  background-color: #b1cead;
  cursor: default;
  z-index: 9999;
}

.footer-button-back {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 30%;
  height: 60px;
  z-index: 999;
  background-color: #414040;
  color: white;
  font-size: 16px;
  border: none;
  cursor: pointer;
  z-index: 9999;
}

.btn {
  background-color: rgb(250, 248, 248);
  color: white;
  font-size: 12px;
  width: 100%;
  transition: background-color 0.3s, color 0.3s;
  margin: 8px;
  border-radius: solid 1px #5db247;
  box-shadow: none;
  outline: none;
}

.btn.selected {
  font-size: 12px;
  background-color: #5db247;
  /* Fundo verde quando selecionado */
  color: white;
  /* Texto branco quando selecionado */
  border-radius: solid 1px #5db247;
  box-shadow: none;
  outline: none;
}

.btn:nth-child(1):not(.selected) {
  font-size: 12px;
  color: #5db247;
  /* Texto verde para o primeiro botão */
}

.btn:nth-child(2):not(.selected) {
  font-size: 12px;
  color: #8f9aae;
}
</style>
